import {ChangeDetectionStrategy, Component, Input, OnDestroy, isDevMode, OnInit} from '@angular/core';
import {NbMenuItem, NbMenuService, NbSidebarService} from '@nebular/theme';
import {UserService} from '../../../@core/data/users.service';
import {AnalyticsService} from '../../../@core/utils/analytics.service';
import {environment} from '../../../../environments/environment';
import {NbAccessChecker} from '@nebular/security';
import {ActivatedRoute, Router} from '@angular/router';
import {combineLatest, Subject} from 'rxjs';
import {Project} from 'src/advanced-bom-api';
import {ProjectGridService} from 'src/app/pages/shared/data-services/project-grid.service';
import {MsalService} from '@azure/msal-angular';
import {
  MeasurementTypeService, MeasurementTypeSelection
} from '@framewerx/kendo-angular-grid';
import {User} from '../../../@core/data/user';

interface ProjectMenuItem {
  name: string;
  projectId: string;
  route: string;
  state?: object;
}

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() position = 'normal';
  @Input() sidebarIsOpen = true;

  private destroy$ = new Subject<void>();
  userPictureOnly = false;

  user: any | User;
  title: string | undefined;
  envProfile = environment.environment;
  isDevMode = isDevMode();
  projectMenuActions: Array<ProjectMenuItem> = [
    {
      name: 'View all projects',
      projectId: '',
      route: 'projects',
    },
    {
      name: 'Add new project',
      projectId: '',
      route: 'projects',
      state: {addNew: true},
    },
  ];
  projectMenuItems: Array<ProjectMenuItem> = [...this.projectMenuActions];
  selectedProject: ProjectMenuItem = this.projectMenuItems[0];
  userMenu: NbMenuItem[] = [
    {
      title: 'Profile',
      url: 'https://portal.office.com/account/#personalinfo',
      target: '_blank',
    },
    {title: 'Logout'},
  ];

  constructor(
    private analyticsService: AnalyticsService,
    private authService: MsalService,
    private menuService: NbMenuService,
    private route: ActivatedRoute,
    private router: Router,
    private sidebarService: NbSidebarService,
    private userService: UserService,
    public accessChecker: NbAccessChecker,
    public projectGridService: ProjectGridService,
    public measurementService: MeasurementTypeService,
  ) {
  }

  login(): void {
    this.authService.loginRedirect();
  }

  navigateToProject(projectMenuItem: ProjectMenuItem) {
    let sidePanelRouteSegment = '';
    const {route} = projectMenuItem;
    const {state} = projectMenuItem;
    const isProjectMenuAction = this.projectMenuActions.find(
      (projectMenuAction) => projectMenuAction.route === projectMenuItem.route
    );

    if (!isProjectMenuAction) {
      sidePanelRouteSegment =
        '/' + window.location.pathname.split('/').slice(3, 4).join('/');
    }

    this.router.navigate([route + sidePanelRouteSegment], {state});

    setTimeout(() => {
      this.selectedProject =
        projectMenuItem.route === this.projectMenuActions[1].route
          ? this.projectMenuActions[0]
          : projectMenuItem;
    });
  }

  ngOnInit() {
    this.userService
      .onUserChange()
      .subscribe({next: (user: any) => (this.user = user)});

    combineLatest([
      this.route.paramMap,
      this.projectGridService.queryAll({
        skip: 0,
        take: 10,
      }),
    ]).subscribe({
      next: ([params, gridDataResult]) => {
        const projects: Array<Project> = gridDataResult.data;
        const projectMenuItems: Array<ProjectMenuItem> = [
          ...this.projectMenuActions,
        ];
        const projectId = params.get('projectId');
        let selectedProject: ProjectMenuItem | undefined;

        projects.forEach((project) => {
          const projectsId = project.id?.toString();

          projectMenuItems.push({
            name: `${project.name} ${project.description}`,
            projectId: projectsId || '',
            route: `projects/${projectsId}`,
          });
        });

        this.projectMenuItems = projectMenuItems;

        if (projectId) {
          selectedProject = this.projectMenuItems.find(
            (projectMenuItem) => projectMenuItem.projectId === projectId
          );
        }

        if (selectedProject) {
          this.selectedProject = selectedProject;
        } else {
          this.selectedProject = this.projectMenuActions[0];
        }

        this.projectMenuActions[0].name =
          projectId !== null ? 'View all projects' : 'Select a project';
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  toggleSettings(): boolean {
    this.sidebarService.toggle(false, 'settings-sidebar');

    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }

  toggleMeasurement() {
    this.measurementService.toggleLengthMeasure();
  }
}
