import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {NbAlertModule} from '@nebular/theme';
import {DatePickerModule, TimePickerModule} from '@progress/kendo-angular-dateinputs';
import {DialogModule} from '@progress/kendo-angular-dialog';
import {DropDownListModule} from '@progress/kendo-angular-dropdowns';
import {
  BodyModule,
  ExcelModule,
  FilterMenuModule,
  GridModule,
  PagerModule,
  PDFModule,
  SharedModule as KendoSharedModule,
} from '@progress/kendo-angular-grid';
import {NumericTextBoxModule, SliderModule} from '@progress/kendo-angular-inputs';
import {IntlModule} from '@progress/kendo-angular-intl';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {environment} from '../../../environments/environment';
import {ThemeModule} from '../../@theme/theme.module';
import {ApplicationPipesModule} from '../../pipes/application-pipes.module';
import {ViewErrorsComponent} from './errors/view-errors/view-errors.component';
import {TimeWrapperComponent} from './time-wrapper/time-wrapper.component';
import {KendoAngularGridConfig, KendoAngularGridModule} from '@framewerx/kendo-angular-grid';
import {FormsModule} from '@angular/forms';
import {PanelBarModule} from '@progress/kendo-angular-layout';
import {TreeViewModule} from '@progress/kendo-angular-treeview';
import {PDFExportModule} from '@progress/kendo-angular-pdf-export';
import {CommonModule} from '@framewerx/common';
import {BackComponent} from './back/back.component';
import {ImportFileSelectorComponent} from './import-file-selector/import-file-selector.component';
import {ItemSelectorDialogComponent} from './item-selector-dialog/item-selector-dialog.component';

import {LengthEditorComponent} from './length-editor/length-editor.component';
import {MassEditorComponent} from './mass-editor/mass-editor.component';
import {HeaderContainerComponent} from './containers/header-container/header-container.component';
import { ProjectSelectionDialogComponent } from './dialogs/project-selection-dialog/project-selection-dialog.component';
import { GridCustomViewCellComponent } from './grid-custom-components/grid-custom-view-cell/grid-custom-view-cell.component';
import { GridCustomEditCellComponent } from './grid-custom-components/grid-custom-edit-cell/grid-custom-edit-cell.component';

export function kendoGridConfigFactory(): KendoAngularGridConfig {
  return {apiRootUrl: `${ environment.apiBaseUrl }`} as KendoAngularGridConfig;
}

@NgModule({
    imports: [
        LoggerModule.forRoot({
            // serverLoggingUrl: '/api/logs', // TODO: Implement logging in server https://stackoverflow.com/a/53078568/2557554
            level: environment.loggingLevel,
            // serverLogLevel: NgxLoggerLevel.ERROR,
            serverLogLevel: NgxLoggerLevel.OFF,
        }),
        ThemeModule,
        FormsModule,
        GridModule,
        ExcelModule,
        PDFExportModule,
        PDFModule,
        SliderModule,
        DialogModule,
        DatePickerModule,
        TimePickerModule,
        IntlModule,
        ApplicationPipesModule,
        NbAlertModule,
        DropDownListModule,
        RouterModule,
        NumericTextBoxModule,
        KendoSharedModule,
        TreeViewModule,
        BodyModule,
        PagerModule,
        FilterMenuModule,
        CommonModule,
        KendoAngularGridModule,
        PanelBarModule,
    ],
    declarations: [
        ViewErrorsComponent,
        BackComponent,
        TimeWrapperComponent,
        ImportFileSelectorComponent,
        ItemSelectorDialogComponent,
        LengthEditorComponent,
        MassEditorComponent,
        HeaderContainerComponent,
        ProjectSelectionDialogComponent,
        GridCustomViewCellComponent,
        GridCustomEditCellComponent,
    ],
    providers: [
    // FilterService,
    // OdataBatchService,
    // {provide: BASE_PATH, useValue: environment.apiBaseUrl},
    ],
    exports: [
        ViewErrorsComponent,
        BackComponent,
        TimeWrapperComponent,
        ImportFileSelectorComponent,
        ItemSelectorDialogComponent,
        LengthEditorComponent,
        MassEditorComponent,
        ProjectSelectionDialogComponent,
        GridCustomViewCellComponent,
        GridCustomEditCellComponent,
    ]
})
export class SharedModule {
  // public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders {
  //   return {
  //     ngModule: SharedModule,
  //     providers: [ { provide: Configuration, useFactory: configurationFactory } ],
  //   };
  // }
}
