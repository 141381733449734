/**
 * ABMwerx API
 * An API for ABMwerx.
 *
 * The version of the OpenAPI document: v1
 * Contact: dan.leder@supremegroup.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CutStatus = 'Okay' | 'NoStockUsed' | 'UnfulfilledDemand' | 'UnCompletedDemand' | 'StockError' | 'NoNesting';

export const CutStatus = {
    Okay: 'Okay' as CutStatus,
    NoStockUsed: 'NoStockUsed' as CutStatus,
    UnfulfilledDemand: 'UnfulfilledDemand' as CutStatus,
    UnCompletedDemand: 'UnCompletedDemand' as CutStatus,
    StockError: 'StockError' as CutStatus,
    NoNesting: 'NoNesting' as CutStatus
};

