import { Injectable } from '@angular/core';
import { TableService } from '@framewerx/kendo-angular-grid';
import { Project, ProjectService } from '../../../../advanced-bom-api';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProjectGridService extends TableService<Project> {
  constructor(http: HttpClient, private clientService: ProjectService) {
    super(http, 'Project');
  }

  getIdUrlPart(data: any | Project): string {
    if (data == null) {
      return '';
    }
    return `/${data.id}`;
  }

  protected cleanupItem(dataItem: Project | any): any {
    delete dataItem.primaryCompany;
    return super.cleanupItem(dataItem);
  }

  add(data: any | Project): Observable<any> {
    return this.clientService.projectPost(this.cleanupItem(data));
  }

  delete(data: any | Project): Observable<any> {
    return this.clientService.projectDelete(data.id);
  }

  update(data: any | Project): Observable<any> {
    return this.clientService.projectPatch(this.cleanupItem(data), data.id);
  }

  protected mapToObject(responseData: any | Project): Project {
    return responseData;
  }

  protected addMetaDataToObject(responseData: any): any {
    if (responseData.hasOwnProperty('changeOrders@odata.count')) {
      responseData['$metadata'].changes =
        responseData['changeOrders@odata.count'];
      delete responseData['changeOrders@odata.count'];
    }
    if (responseData.hasOwnProperty('breakdowns@odata.count')) {
      responseData['$metadata'].breakdowns =
        responseData['breakdowns@odata.count'];
      delete responseData['breakdowns@odata.count'];
    }
    if (responseData.hasOwnProperty('workTasks@odata.count')) {
      responseData['$metadata'].workTasks =
        responseData['workTasks@odata.count'];
      delete responseData['workTasks@odata.count'];
    }
    if (responseData.hasOwnProperty('tradeUnions@odata.count')) {
      responseData['$metadata'].tradeUnions =
        responseData['tradeUnions@odata.count'];
      delete responseData['tradeUnions@odata.count'];
    }
    if (responseData.hasOwnProperty('workPackages@odata.count')) {
      responseData['$metadata'].workPackages =
        responseData['workPackages@odata.count'];
      delete responseData['workPackages@odata.count'];
    }
    return super.addMetaDataToObject(responseData);
  }
}
