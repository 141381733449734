/**
 * ABMwerx API
 * An API for ABMwerx.
 *
 * The version of the OpenAPI document: v1
 * Contact: dan.leder@supremegroup.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ShapeType = 'Imperial' | 'Metric';

export const ShapeType = {
    Imperial: 'Imperial' as ShapeType,
    Metric: 'Metric' as ShapeType
};

