import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserService} from './users.service';
import {StateService} from './state.service';
import {MicrosoftGraphService} from '../microsoft-graph/microsoft-graph.service';
import {CookieService} from 'ngx-cookie-service';
import {StatePersistingService} from '@framewerx/kendo-angular-grid';

const SERVICES = [
  CookieService,
  UserService,
  StateService,
  MicrosoftGraphService,
  StatePersistingService,
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers: [
    ...SERVICES,
  ],
  exports: [],
})
export class DataModule {
  static forRoot(): ModuleWithProviders<DataModule> {
    return {
      ngModule: DataModule,
      providers: [
        ...SERVICES,
      ],
    };
  }
}
