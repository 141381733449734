import {
  ExtraOptions,
  PreloadAllModules,
  RouterModule,
  Routes,
} from '@angular/router';
import {NgModule} from '@angular/core';
import {MsalRedirectComponent} from '@azure/msal-angular';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
  },
  {path: 'auth', component: MsalRedirectComponent},
  {path: '**', redirectTo: '', pathMatch: 'full'},
];

const config: ExtraOptions = {
  enableTracing: false,
  useHash: false,
  preloadingStrategy: PreloadAllModules,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
