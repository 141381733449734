<div class="header-container"
  style="gap: 6px"
  [class.left]="position === 'normal'"
  [class.right]="position === 'inverse'">
  <div style="cursor: pointer" [routerLink]="['/']" class="logo-container">
    <div
      style="
        background-image: url(android-chrome-192x192.png);
        height: 24px;
        width: 24px;
        background-size: contain;
      "
      [style.margin]="sidebarIsOpen ? '-10px 0px 0px -2px' : '-10px 4px 0px -6px'"
    ></div>

    <div *ngIf="sidebarIsOpen" style="font-size: 1.5rem;" class="logo">Abm<span>Werx</span></div>
  </div>
  <!--  <ngx-theme-switcher></ngx-theme-switcher>-->

  <kendo-dropdownlist
    *ngIf="selectedProject.projectId"
    [data]="projectMenuItems"
    class="project-menu-button"
    textField="name"
    valueField="route"
    [popupSettings]="{ popupClass: 'project-menu-popup', animate: true, width: 'auto' }"
    [(ngModel)]="selectedProject"
    (selectionChange)="navigateToProject($event)">
  </kendo-dropdownlist>

</div>

<div class="header-container">
  <span class="k-h6 align-baseline">Length:</span>
  <div class="toolbar-switcher align-baseline">
    <kendo-buttongroup
      selection="single"
      fillMode="flat">
      <button
        kendoButton
        type="button"
        [toggleable]="true"
        [selected]="(measurementService.selection | async)?.measureType === 'Metric'"
        [primary]="(measurementService.selection | async)?.measureType === 'Metric'"
        (click)="toggleMeasurement()"
      >
        Metric
      </button>
      <button
        kendoButton
        type="button"
        [toggleable]="true"
        [selected]="(measurementService.selection | async)?.measureType === 'Imperial'"
        [primary]="(measurementService.selection | async)?.measureType === 'Imperial'"
        (click)="toggleMeasurement()"
      >
        Imperial
      </button>
    </kendo-buttongroup>
  </div>
</div>

<div class="header-container">
  <!--<ngx-layout-direction-switcher></ngx-layout-direction-switcher>-->
  <div style="font-size: 12px;" class="font-w-bold logo text-uppercase" [ngClass]="{'text-success': isDevMode, 'text-danger': !isDevMode}"
    *ngIf="envProfile != null && envProfile.trim().length > 0">
  {{ envProfile }}
  </div>

  <nb-actions
      size="medium"
      [class.right]="position === 'normal'"
      [class.left]="position === 'inverse'">
    <!-- <nb-action *ngIf="isDevMode" icon="fa-cog" class="toggle-layout" (click)="toggleSettings()"></nb-action> -->
    <nb-action *ngIf="accessChecker.isGranted('view', 'profile') | async; else loginAction">
      <nb-user [nbContextMenu]="userMenu" [name]="user?.name" [picture]="user?.picture"></nb-user>
    </nb-action>

    <ng-template #loginAction>
      <nb-action>
        <button nbButton (click)="login()">Login</button>
      </nb-action>
    </ng-template>
    <!--<nb-action class="control-item" disabled icon="notifications"></nb-action>-->
    <!--<nb-action class="control-item" icon="email"></nb-action>-->
    <!--<nb-action class="control-item">-->
    <!--<nb-search type="rotate-layout" (click)="startSearch()"></nb-search>-->
    <!--</nb-action>-->
  </nb-actions>
</div>
