import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
	<small class="created-by">Created with ♥ by <b><a href="https://framewerx.ca" target="_blank">Framewerx</a></b> 
		© 2020 - {{currentYear}}</small>
  `,
})
export class FooterComponent {
  currentYear = new Date().getFullYear();
}
