import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import * as MicrosoftGraphClient from '@microsoft/microsoft-graph-client';
import {Injectable} from '@angular/core';
import {MsalService} from '@azure/msal-angular';
import {NGXLogger} from 'ngx-logger';
import {from, Observable} from 'rxjs';
import {MsalCustomInterceptor} from '../../pages/auth/msal-custom-interceptor';

@Injectable()
export class MicrosoftGraphService {
  url = 'https://graph.microsoft.com/v1.0';

  constructor(private logger: NGXLogger, private authService: MsalService, private interceptor: MsalCustomInterceptor) {
  }

  getClient(): MicrosoftGraphClient.Client {
    return MicrosoftGraphClient.Client.init({
      authProvider: (done) => {
        const scopes = this.interceptor.getScopesForEndpoint(this.url, 'GET');

        if (scopes !== null) {
          this.authService.acquireTokenSilent({scopes})
            .subscribe({next: (result) => {
              this.logger.trace(`MSAL token acquired silently for Microsoft Graph Service: ${JSON.stringify(result)}`);
              done(null, result.accessToken); // first parameter takes an error if you can't get an access token
            }});
        }
      },
    });
  }

  getMe(): Observable<MicrosoftGraph.User> {
    const client = this.getClient();
    return from(client
      .api('me')
      .get()
      .then((res => {
        return res;
      })),
    );
  }

  getMyPhoto(): Observable<MicrosoftGraph.ProfilePhoto> {
    const client = this.getClient();
    return from(client
      .api('me/photo')
      .get()
      .then((res => {
        return res;
      })),
    );
  }

  sendMail(mail: MicrosoftGraph.Message) {
    const client = this.getClient();
    return from(client
      .api('me/sendmail')
      .post({message: mail}),
    );
  }


}
