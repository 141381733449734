import {Injectable} from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderTitleService {
  protected menuItem: BehaviorSubject<NbMenuItem> = new BehaviorSubject({
    title: '',
  });

  constructor() {}

  publishHeader(menuItem: NbMenuItem): void {
    this.menuItem.next(menuItem);
  }

  onHeaderChange(): Observable<NbMenuItem> {
    return this.menuItem;
  }
}
