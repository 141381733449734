import {Component, InjectionToken, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {KendoAngularGridConfig} from '@framewerx/kendo-angular-grid';
import {NGXLogger} from 'ngx-logger';
import {NbDialogService, NbIconLibraries, NbMenuService} from '@nebular/theme';
import {MsalBroadcastService, MsalCustomNavigationClient, MsalService} from '@azure/msal-angular';
import {AnalyticsService} from './@core/utils/analytics.service';
import {AuthenticationResult, EventMessage, EventType, InteractionStatus} from '@azure/msal-browser';
import {filter, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {UserService} from './@core/data/users.service';
import {environment} from '../environments/environment';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {ConfirmPromptComponent} from './@theme/components/modal/confirm-prompt.component';

export const KENDO_ANGULAR_GRID_CONFIG = new InjectionToken<KendoAngularGridConfig>('KENDO_ANGULAR_GRID_CONFIG');

@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit, OnDestroy {

  protected destroy$ = new Subject<boolean>();
  logoutTitle = 'Logout';

  constructor(
    private analytics: AnalyticsService,
    private authService: MsalService,
    private cookieService: CookieService,
    private dialogService: NbDialogService,
    private iconLibraries: NbIconLibraries,
    private location: Location,
    private logger: NGXLogger,
    private menuService: NbMenuService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private userService: UserService,
  ) {
    // this.iconLibraries.registerFontPack('font-awesome', {packClass: 'fa', iconClassPrefix: ''});
    // this.iconLibraries.registerFontPack('font-awesome-solid', {packClass: 'fas', iconClassPrefix: ''});
    // this.iconLibraries.registerFontPack('font-awesome-brand', {packClass: 'fa-brands', iconClassPrefix: ''});
    // this.iconLibraries.setDefaultPack('font-awesome');

    // See https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/performance.md
    const customNavigationClient = new MsalCustomNavigationClient(this.authService, this.router, this.location);
    this.authService.instance.setNavigationClient(customNavigationClient);

    this.iconLibraries.registerFontPack('fas', {packClass: 'fas', iconClassPrefix: ''});
    this.iconLibraries.registerFontPack('far', {packClass: 'far', iconClassPrefix: ''});
    this.iconLibraries.registerFontPack('fab', {packClass: 'fab', iconClassPrefix: ''});
    this.iconLibraries.setDefaultPack('fas');


    this.menuService.onItemClick().subscribe({
      next: (e) => {
        if (e.item.title === this.logoutTitle) {
          this.dialogService
            .open(ConfirmPromptComponent, {
              hasBackdrop: true,
              closeOnEsc: true,
              autoFocus: true,
              context: {
                title: 'Are you sure you want to logout?',
                body: 'You will lose any data that has not been saved yet!',
                button2Text: 'Cancel',
                button2Result: false,
                button1Text: 'Logout',
                button1Result: true,
              },
            })
            .onClose.subscribe({
            next: (confirm) => {
              if (confirm === true) {
                this.authService.logout();
                sessionStorage.clear();
                this.cookieService.deleteAll('/', window.location.hostname);
              }
            }
          });
        }
      }
    });
  }

  saveAuthToken(payload: AuthenticationResult) {
    this.authService.instance.setActiveAccount(payload.account);

    // Save the token to Cookie
    let date = null;
    // @ts-ignore
    if (payload?.idTokenClaims?.exp != null) {
      // @ts-ignore
      date = new Date(payload.idTokenClaims.exp * 1000);
    }
    if (date != null) {
      this.userService.saveIdToken(payload?.idToken, date);
    }

    this.userService.publishUser(payload?.account?.idTokenClaims);
  }

  ngOnInit(): void {
    this.analytics.trackPageViews();

    this.msalBroadcastService.inProgress$
      .pipe(
        takeUntil(this.destroy$),
        filter((status: InteractionStatus) => status === InteractionStatus.None),
      )
      .subscribe({
        next: (result) => {
          this.logger.trace('inProgress$: ' + JSON.stringify(result));
          this.checkAndSetActiveAccount();
        }
      });
    this.msalBroadcastService.msalSubject$
      .pipe(
        takeUntil(this.destroy$),
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe({
        next: (result: EventMessage) => {
          const payload = result.payload as AuthenticationResult;
          this.logger.trace('msal:loginSuccess: ' + JSON.stringify(payload));
          this.saveAuthToken(payload);
        }
      });
    this.msalBroadcastService.msalSubject$
      .pipe(
        takeUntil(this.destroy$),
        filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
      ).subscribe({
      next: (result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        // this.logger.trace('msal:acquireTokenSuccess: ' + JSON.stringify(payload));
        if (payload.fromCache === false && payload.authority === environment.authority) {
          this.saveAuthToken(payload);
          this.logger.trace('msal:acquireTokenSuccess: updated local service auth token and cookie!');
        }
      }
    });
  }

  // See https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/b00e5be121a97e8ad5a610a21175b05e6858c3ac/samples/msal-angular-v2-samples/angular12-sample-app/src/app/app.component.ts#L43
  private checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      this.logger.trace('checkAndSetActiveAccount: No active account set but active accounts present...');
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
      this.userService.publishUser(accounts[0].idTokenClaims);
      this.logger.trace('checkAndSetActiveAccount: Active account set to: ' + JSON.stringify(accounts[0]));
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}
