import {ErrorHandler, Injectable, Injector, NgZone} from '@angular/core';
import {ApplicationInsightsService} from './application-insights.service';
import {ErrorService} from '../../pages/shared/errors/error.service';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService extends ErrorHandler {

  constructor(private injector: Injector, private errorService: ErrorService, private zone: NgZone) {
    super();
  }

  handleError(error: any) {
    if (!(error instanceof HttpErrorResponse)) {
      const appInsightService = this.injector.get(ApplicationInsightsService);
      if (appInsightService.appInsights != null) {
        appInsightService.appInsights.trackException({exception: error});
      }
    }
    super.handleError(error);
    // required zone to allow errorService to run with change detection
    this.zone.run(() => this.errorService.next(error)); // change detection not popping up dialog
  }

}
