import {NgxLoggerLevel} from 'ngx-logger';
import {LogLevel} from '@azure/msal-browser';

export const environment = {
  appVersion: require('../../package.json').version,
  production: true,
  environment: '',
  loggingLevel: NgxLoggerLevel.WARN,
  msalLoggingLevel: LogLevel.Warning,
  authority: 'https://login.microsoftonline.com/ddcdd9fc-eafc-4955-bdd4-1463255c7dc8/',
  azureClientId: 'dff943ff-9ed4-4f5d-bffa-571c4b864423',
  apiBaseUrl: window.location.origin,
  protectedResourceMap: [
    ['https://graph.microsoft.com/v1.0', ['User.Read', 'User.Read.All', 'User.ReadBasic.All', 'User.ReadWrite']],
    ['/api/v1/Environment', null], // unprotected route have null scope
    ['/api/*', ['api://dff943ff-9ed4-4f5d-bffa-571c4b864423/Data.Read.All', 'api://dff943ff-9ed4-4f5d-bffa-571c4b864423/Data.Write.All']],
    // ... other scopes
  ],
  appInsights: {
    instrumentationKey: null,
  },
  stemsApiBaseUrl: window.location.origin + '/_stems'
};
