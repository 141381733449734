import {Injectable} from '@angular/core';
import {NbRoleProvider} from '@nebular/security';
import {MsalService} from '@azure/msal-angular';
import {Observable, of} from 'rxjs';

@Injectable()
export class AzureRoleProvider implements NbRoleProvider {

  constructor(private authService: MsalService) {
  }

  getRole(): Observable<string[]> {
    const activeAccountToken = this.authService.instance.getActiveAccount()
      ?.idTokenClaims as { [key: string]: unknown };

    if (activeAccountToken != null) {
      return of(activeAccountToken['roles'] as string[]);
    } else {
      return of(['guest']);
    }
  }
}
