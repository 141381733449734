import {NGXLogger} from 'ngx-logger';
import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {ErrorResponse} from '../../../../advanced-bom-api';

@Injectable({providedIn: 'root'})
export class ErrorService extends Subject<ErrorResponse | any> {

  constructor(private logger: NGXLogger) {
    super();
  }

}
