import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {delay, takeWhile, withLatestFrom} from 'rxjs/operators';
import {
  NbMediaBreakpointsService,
  NbMenuItem,
  NbMenuService,
  NbSidebarComponent,
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme';

import {StateService} from '../../../@core/data/state.service';
import {HeaderTitleService} from '../../services/header-title.service';
// import {ConnectionService} from 'ngx-connection-service';

// TODO: move layouts into the framework
@Component({
  selector: 'ngx-sample-layout',
  styleUrls: ['./sample.layout.scss'],
  template: `
      <nb-layout [center]="layout.id === 'center-column'" [windowMode]="false">
          <nb-layout-header fixed class="header">
              <ngx-header
                      [position]="sidebar?.id === 'start' ? 'normal': 'inverse'"
                      [sidebarIsOpen]="sidebarIsOpen"
              ></ngx-header>
          </nb-layout-header>

          <nb-layout-header subheader *ngIf="!hasInternetAccess">
              <div class='alert alert-danger w-100' role='alert' *ngIf="!hasInternetAccess">
                  You do not appear to have an Internet connection!
              </div>
          </nb-layout-header>

          <nb-layout-header subheader>
              <div
                      style="text-align: center; font-size: 1.5rem; cursor: pointer; margin-right: 24px;"
                      [routerLink]="[menuItem.link]"
              >
                  {{ menuItem.title }}
              </div>
          </nb-layout-header>

          <nb-sidebar tag="menu-sidebar"
                      responsive
                      [end]="sidebar.id === 'end'"
                      (stateChange)="sidebarIsOpen = $event === 'expanded'"
                      #menuSidebar>
              <!--<nb-sidebar-header *ngIf="currentTheme !== 'corporate'">-->
              <!--<a href="#" class="btn btn-hero-success main-btn">-->
              <!--<i class="ion ion-social-github"></i> <span>Support Us</span>-->
              <!--</a>-->
              <!--</nb-sidebar-header>-->
              <ng-content select="nb-menu"></ng-content>

              <nb-sidebar-footer
                      style="padding: 0px 6px; display: flex; align-items: center;"
              >
                  <button
                          nbButton
                          style="width: 100%; justify-content: flex-end;"
                          ghost
                          (click)="menuSidebar.toggle(true)"
                  >
                      <nb-icon
                              [icon]="sidebarIsOpen ? 'fa-chevron-left' : 'fa-chevron-right'"
                      ></nb-icon>
                  </button>
              </nb-sidebar-footer>
          </nb-sidebar>

          <nb-layout-column style="padding: 6px" class="main-content">
              <div class="container-fluid">
                  <ng-content></ng-content>
              </div>
          </nb-layout-column>

          <nb-layout-column start class="small" *ngIf="layout.id === 'two-column' || layout.id === 'three-column'">
              <nb-menu [items]="subMenu"></nb-menu>
          </nb-layout-column>

          <nb-layout-column class="small" *ngIf="layout.id === 'three-column'">
              <nb-menu [items]="subMenu"></nb-menu>
          </nb-layout-column>

          <nb-layout-footer fixed class="pt-5">
              <ngx-footer></ngx-footer>
          </nb-layout-footer>

          <!-- <nb-sidebar class="settings-sidebar"
                      tag="settings-sidebar"
                      state="collapsed"
                      fixed
                      [end]="sidebar.id !== 'end'">
              <ngx-theme-settings></ngx-theme-settings>
          </nb-sidebar> -->
      </nb-layout>
  `,
})
export class SampleLayoutComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('menuSidebar') menuSidebar!: NbSidebarComponent;

  subMenu: NbMenuItem[] = [
    {
      title: 'PAGE LEVEL MENU',
    },
  ];
  menuItem: NbMenuItem = {title: '', link: ''};
  layout: any = {};
  sidebar: any = {};
  sidebarIsOpen = false;

  private alive = true;

  currentTheme: string | undefined;

  hasInternetAccess = true;
  hasNetworkConnection = true;
  status: string | undefined;

  constructor(protected stateService: StateService,
              protected menuService: NbMenuService,
              protected themeService: NbThemeService,
              protected bpService: NbMediaBreakpointsService,
              protected sidebarService: NbSidebarService,
              private headerService: HeaderTitleService,
              // protected connectionService: ConnectionService
  ) {
    this.stateService.onLayoutState()
      .pipe(takeWhile(() => this.alive))
      .subscribe({next: (layout: string) => this.layout = layout});

    this.stateService.onSidebarState()
      .pipe(takeWhile(() => this.alive))
      .subscribe({
        next: (sidebar: any) => {
          this.sidebar = sidebar;
        }
      });

    const isBp = this.bpService.getByName('is');
    this.menuService.onItemSelect()
      .pipe(
        takeWhile(() => this.alive),
        withLatestFrom(this.themeService.onMediaQueryChange()),
        delay(20),
      )
      .subscribe({
        next: ([item, [bpFrom, bpTo]]) => {

          if (bpTo.width <= isBp.width) {
            this.sidebarService.collapse('menu-sidebar');
          }
        }
      });

    this.themeService.getJsTheme()
      .pipe(takeWhile(() => this.alive))
      .subscribe({
        next: theme => {
          this.currentTheme = theme.name;
        }
      });

    // this.connectionService.monitor().subscribe(currentState => {
    //   this.hasNetworkConnection  = currentState.hasNetworkConnection;
    //   this.hasInternetAccess = currentState.hasInternetAccess;
    //   if (this.hasInternetAccess && this.hasNetworkConnection) {
    //     this.status = 'ONLINE';
    //   } else {
    //     this.status = 'OFFLINE';
    //   }
    // });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.sidebarIsOpen = this.menuSidebar.state === undefined || this.menuSidebar.state === 'expanded';
    });
  }

  ngOnInit() {
    this.headerService
      .onHeaderChange()
      .subscribe({next: (menuItem) => (this.menuItem = menuItem)});
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
