import {SeparateCamelCasePipe} from './separate-camel-case.pipe';
import {NgModule} from '@angular/core';
import {ArrayFilterPipe} from './array-filter.pipe';
import { SameDayPipe } from './same-day.pipe';
import { ImperialLengthPipe } from './imperial-length.pipe';

@NgModule({
  imports: [],
  declarations: [
    SeparateCamelCasePipe,
    ArrayFilterPipe,
    SameDayPipe,
    ImperialLengthPipe,
  ],
    exports: [
        SeparateCamelCasePipe,
        ArrayFilterPipe,
        SameDayPipe,
        ImperialLengthPipe,
    ],
})
export class ApplicationPipesModule {
}
